$width: 60vw;
$height: 40vh;

div.map, div.map * {
	max-width: $width !important;
	max-height: $height !important;
}

div.map-parent {
	width: $width;
	height: $height;
	
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

	margin: 3em auto;
	margin-bottom: 1em;
}

@media only screen and (max-width: 600px){
	$width: 80vw;

	div.map, div.map * {
		max-width: $width !important;
	}

	div.map-parent {
		margin: 1em auto;
		width: $width;
	}
}
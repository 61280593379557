@import "../_config.scss"; /* Change to @use in the future */

$size-card: 400px;
$size: 300px;

.card-container-patro {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax($size-card, 1fr));
  padding: 3rem 0;

  div.card {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    margin: 0 auto;

    .imgcover {
      margin: 0 auto;

      width: $size !important;
      min-width: $size !important;
      max-width: $size;

      height: $size !important;
      min-height: $size !important;
      max-height: $size !important;
    }
  }
}

@import '../_config.scss';

.wrapper {
	margin-bottom: 10vh;
}

input {
    border: none;
    border-bottom: 2px solid black;
    margin: 5px 10px;
    padding: 0;
    padding-top: 6px;
    text-align: center;
}

form.formulario-socios {
    &>div {
        margin: 0.2em 0;
    }

	input[type="checkbox"]{
		margin-left: 0;
	}

    button[type="submit"] {
        font-size: 1.1rem;

		margin: 1em;
        padding: 0.5em 1em;

		border-radius: 0.35em;

		&:disabled {
			box-shadow: none;
			border: 1px solid lightgrey;
			background-color: transparent;
			color: lightgrey;
		}

		&:not(:disabled){
			box-shadow: 0 3px 5px 0 grey;
			border: 1px solid black;
			background-color: $button-accent;
			color: lightgrey;
			cursor: pointer;

			transition: all 500ms ease;

			&:hover {
				box-shadow: 0 6px 5px 2px grey;
				transform: scale(1.05) translateY(-8px);
			}
		}
    }
}
p.map-address {
	width: fit-content;

	text-align: center;

	margin: auto !important;
	margin-bottom: 2em !important;
}

section {
	ul {
		margin-inline: 0;
		padding-inline: 0;
		padding-inline-start: 2ch;
	}

	& > *:not(:first-child){
		margin-left: 1em;
	}
}
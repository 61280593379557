@import "_config.scss"; /* Change to @use in the future */

html, body {
	margin: 0;
	width: 100vw;
}

body {
	font-family: Arial, Helvetica, sans-serif;
	overflow-x: hidden;
}

@media only screen and (max-device-width: 480px) {
	body {
		font-size: 0.8em !important;
	}
}

footer {
	padding: 10px;
	padding-left: 20px;
	background-color: $header-bg;
	color: $header-fg;
}

.header {
	background: rgb(233, 233, 233);
	display: block;
	
	.header-img {
		justify-content: center;
		text-align:center;

		width: 100%;

		/*&::after {
			content: "AFADLA";
			font-size: 2rem;
		
			color: greenyellow;
		
			position: relative;
			bottom: 1rem;
			width: 100%;
			vertical-align: middle;
			background: linear-gradient(to top, rgba(255,255,255, 0.85), transparent);
		
			transform: translate(-50%, 0);
		}*/

		img{
			max-width: 100%;
		}
	}
}

.main-body {
	padding: 10px 20px;
	padding-right: 20px;
	min-height: 45vh;
}

@import "../_config.scss"; /* Change to @use in the future */

.sponsors {
  font-size: 1.2rem;

  li {
    margin: 30px;
  }
}

.card-container-home {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;

  div.card {
    display: flex;
    flex-direction: column;
    height: fit-content;

    .imgcover {
      $size: 250px;

      display: flex;
      box-sizing: border-box;

      max-width: $size !important;
      max-height: $size !important;
      width: $size !important;
      height: $size !important;
      min-height: $size !important;
      min-width: $size !important;

      margin: 0 auto;
      padding: auto;
    }
    a {
      box-sizing: border-box;
      margin: 1em;
      padding: 0.5em 5em;
    }
  }
}
.hcard-container {
	max-height: 1000px !important;
}

.bottom-container {
  display: flex;
  flex-direction: row;

  .description-and-patreons {
    margin-left: 25px;
    margin-right: 40px;
    flex-shrink: 2;
  }
}

@media only screen and (max-width: 600px) {
  .bottom-container {
    display: flex;
    flex-direction: column;

    margin-left: 0 !important;
  }
}

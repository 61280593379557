@import "_config.scss"; /* Change to @use in the future */

div.card {
	display: inline-block;

	padding: 20px 10px;
	margin: $card-margin;

	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	text-align: center;

	width: fit-content;
	min-width: 200px;
	max-width: 400px;

	.imgcover {
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		width: 100%;
		height: 100%;
		margin: 0;

		img {
			box-sizing: border-box;
			object-fit: cover;
			margin: 0;
			width: fit-content;
			max-width: 100%;
			height: fit-content;
			max-height: 100%;
		}
	}

	p.card-text {
		word-wrap: break-word;
		height: 3em;
	}

	a {
		color: $header-fg;
		background-color: $header-bg;
	
		text-decoration: none;
		font-size: larger;
	
		padding: 10px 10px;
		margin: 25px 5px;

		transition: background-color 200ms ease;

		&:hover {
			background-color: $button-accent;
		}
	}
}

@media only screen and (max-device-width: 480px) {
	div.card {
		min-width: 30%;
		max-width: 80%;

		padding: 1rem;
		margin: $card-margin-mobile;

		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);

		img {
			display: none;
			max-width: 40%;
			margin: 0 20px;
		}
	}

	div.hcard {
		width: 90% !important;
		margin: 30px 0 !important;
	}
}

.news-container {
	flex-grow: 2; 
	flex-basis: 80vw;

	margin-right: 10px;
}

div.hcard-container {
	width: 100%;
	max-width: 60vw;

	max-height: 110vh;

	padding: 0 5px;

	box-shadow: inset -11px 0px 17px 0px rgba(0,0,0,0.43);

	overflow-y: scroll;

	div.hcard {
		display: flex;
		flex-wrap: wrap;

		padding: 20px 10px;
		padding-top: 0;
		margin: 30px 0;
		margin-right: 1em !important;

		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		text-align: center;
		vertical-align: middle;

		max-width: 100%;

		height: fit-content;
		/* max-height: 40vh; */

		transform: translate(5px, 0);

		&:first-child {
			margin-top: 0.75rem !important;
		}

		&:last-child {
			margin-bottom: 0.75rem !important;
		}

		/* .hcard-title {
			text-align: right;
		} */

		.flex-row {
			width: 100%;
			margin-top: 1em;
		}

		.hcard-img{
			flex-basis: 20%;
			flex-shrink: 0;
			/* margin: 10px 0; */
			/* padding-top: 5%; */
			vertical-align: middle;

			img {
				width: 10em;
			}
		}
		.date {
			color: #aaa;
			margin: 0;
		}

		h3 {
			margin: 5px;
		}

		a {
			color: $link-accent;
			/*text-decoration: none;*/
		}
	}

	/* CUSTOM SCROLLBAR */
		/* width */
		&::-webkit-scrollbar {
			width: 10px;
		}
		
		/* Track */
		&::-webkit-scrollbar-track {
			background: #f1f1f1;
		}
		
		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: #888;
		}
		
		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: #555;
		}
}

div.card, div.hcard{
	p {
		margin-bottom: 35px;
	}
}


@media only screen and (max-width: 600px){
	div.hcard-container {
		max-width: 100vw;
	}

	.news-container {
		flex-basis: unset;
		margin: 0 0.5em;
	}
}
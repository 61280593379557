$button-accent: #2050aa;
$link-accent: green;

/* These are the background and foreground colors of both the navbar */
/* and the footer, as well as all the "buttons" (links)*/
$header-fg: white;
$header-bg: #242424; 

$card-margin: 15px;
$card-margin-mobile: 0.3rem;
$maxphotoW: 4em;
@import "_config.scss"; /* Change to @use in the future */

.navbar {
	width: 100%;
	min-height: 3em;

	display: flex;
	justify-content: center;
	align-items: stretch;
	flex-wrap: wrap;

	background-color: $header-bg;
	color: $header-fg;

	font-size: 1.5em;

	padding: 0;

	a {
		color: $header-fg;
		text-decoration: none;

		text-align: center;
		vertical-align: bottom;

		margin: 0;
		padding: 1.2rem;
		padding-left: 20px;
		padding-right: 20px;

		transition: background-color 200ms ease;
		
		&:hover {
			text-decoration: none;
			background-color: $button-accent;
		}
	}
}

@media screen and (max-device-width: 480px) {
	.navbar {

		a {
			margin: 2px;
			padding: 0.5rem;
			border: 1px solid darkgray;
		}
	}
}